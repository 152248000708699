import { useTranslation } from 'gatsby-plugin-react-i18next';
import gsap from 'gsap';
import { TweenMax } from 'gsap/gsap-core';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import BannerWorksImg from './banner-works.svg';

const BannerWorks = () => {
  const el = useRef()
  const q = gsap.utils.selector(el)
  const { t } = useTranslation()

  useEffect(() => {
    const puzzleBlue = q('#puzzleblue');
    TweenMax.from(puzzleBlue, 1, {transformOrigin: "50% 50%", y: '-=10', x: '+=10', repeat: -1, yoyo: true});
  }, [])

  return (
    <section ref={el} className="banner-section h-full flex flex-col">
      <div className="banner-wrapper">
        <h1 className="page-title mr-auto relative p-6 md:p-0">
          {t('our')}
          <br />
          <span className="text-primary font-bold">
            {t('works')}
          </span>
        </h1>
        <div className="banner banner-works">
          <BannerWorksImg />
        </div>
      </div>
    </section>
  )
}

export default BannerWorks;