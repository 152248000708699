import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerWorks from "../components/banner/works"
import FreeConsultationForm from "../components/free-consultation-form"
import WorkCard from "../components/work/work-card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "gatsby-plugin-react-i18next"
import isBrowser from "../utils/isBrowser"
import { IconButton } from "../components/icon-link-button"

function ViewMore({
  navRef,
  visible = true,
}) {
  if (!visible) {
    return null;
  }

  const { t } = useTranslation()

  return (
    <div className="py-14 text-center">
      <IconButton
        onClick={() => {
          // @ts-ignore-next-line
          navRef.current.scrollIntoView({ behavior: "smooth" })
        }}
        icon={faChevronUp}
      >
        {t('view other works')}
      </IconButton>
    </div>
  )
}

function NoResult({ visible = false }) {
  if (!visible) {
    return null;
  }

  const { t } = useTranslation()

  return (
    <p className="text-center">
      {t('No recent works found under this category, please try other category.')}
    </p>
  )
}

const WorksPage = ({ data, pageContext, location }) => {
  const { services, allStrapiWork, strapiGlobal } = data

  const processedServices = services.servicesOrder.map(({ service }) => service)

  // filter any services out
  const serviceToExclude = [
    'sem',
  ]

  const filteredServices = processedServices.filter(({ slug }) => !serviceToExclude.includes(slug))
  const filteredServicesKeys = processedServices.map(({ slug }) => slug)

  const locationHash = location.hash.replace('#', '')
  const defaultActiveNav = filteredServicesKeys.includes(locationHash) ? locationHash : 'all'
  const [activeNav, setActiveNav] = useState(defaultActiveNav)
  const [isNavVisible, setIsNavVisible] = useState(false)
  const navRef = useRef(null)
  const { t } = useTranslation()
  
  useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    
    if (activeNav === "all") {
      window.location.hash = '';
      return;
    }

    window.location.hash = activeNav
  }, [activeNav])

  const seo = {
    metaTitle: t('our works'),
    lang: pageContext.strapiLangKey,
  }

  const navItems = [
    {
      title: t('all'),
      slug: 'all',
    },
    ...filteredServices,
  ];

  const navItemClasses = [
    'bg-concrete',
    'border-b-[2px]',
    'border-secondary/10',
    'md:border-transparent',
    'py-2',
    'text-2xl',
    'md:hover:text-primary',
    'md:hover:border-primary',
    'md:hover:border-b-[1px]',
    'md:font-bold',
    'md:bg-transparent',
  ];

  const activeNavItemClasses = [
    'md:text-primary',
    '!border-b-[1px]',
    'font-bold',
    'md:font-regular',
  ]

  const navWrapperClasses = [
    'flex',
    'flex-wrap',
    'flex-col',
    'md:flex-row',
    'justify-center',
    'md:space-x-8',
    'overflow-hidden',
    'md:overflow-auto',
    'md:h-auto',
    'transition transition-all',
  ];
  
  const workArray = allStrapiWork.nodes;
  let filteredResults = workArray;

  if (activeNav !== 'all') {
    filteredResults = workArray.filter(({ services }) => services.some(({ slug }) => slug === activeNav));
  }

  const hasWorks = filteredResults.length > 0;

  return (
    <Layout>
      <Seo seo={seo} />
      <BannerWorks />
      <main>
        <div
          ref={navRef}
          className="w-screen md:w-full md:container flex flex-wrap flex-col"
        >
          <button
            className={[
              'font-bold',
              'md:hidden',
              ...navItemClasses
            ].join(' ')}
            onClick={() => setIsNavVisible(!isNavVisible)}
          >
            portfolio categories
            &nbsp;
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div className={[
            isNavVisible ? 'h-auto' : 'h-0',
            ...navWrapperClasses,
          ].join(' ')}>
            { navItems.map(({
              id,
              title,
              slug
            }) => (
              <button
                key={`service-nav-${id}`}
                className={[
                  ...navItemClasses,
                  ...(activeNav === slug ? activeNavItemClasses : []),
                ].join(' ')}
                onClick={() => setActiveNav(slug)}
              >
                {title}
              </button>
            ))}
          </div>
        </div>
        <section className="container">
          <div className="py-14">
            { filteredResults.map((work, index) => (
              <WorkCard
                key={`work-test-${work.id}-${index}`}
                odd={index % 2}
                {...work}
              />
            )) }
            <NoResult visible={!hasWorks} />
          </div>
          <ViewMore {...{navRef, visible: hasWorks}} />
        </section>
        <FreeConsultationForm {...{ contactDetails: strapiGlobal.contactDetails }} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!, $strapiLangKey: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      ...Locales
    }
    strapiGlobal(locale: {eq: $strapiLangKey}) {
      ...ContactDetails
    }
    services: strapiGlobal(locale: {eq: $strapiLangKey}) {
      ...ServicesOrder
    }
    allStrapiWork(
      filter: {locale: {eq: $strapiLangKey}}
      sort: {fields: publishDate, order: DESC}
    ) {
      nodes {
        id
        title
        excerpt {
          data {
            excerpt
          }
        }
        services {
          id
          slug
          title
        }
        featureImage {
          alternativeText
          ... on STRAPI__MEDIA {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 788, placeholder: BLURRED)
              }
            }
          }
        }
        link {
          id
          type
          url
        }
        slug
      }
    }
  }
`

export default WorksPage
